import DownloadOutlined from '@ant-design/icons/DownloadOutlined';
import dayjs from 'dayjs';
import Image from 'next/image';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { GetSignedUserDataQueryHookResult, useGetSignedUserDataQuery } from '../../../graphql/types';

import { mainPages } from '../../customTypes';
import { checkPage, useSmallScreen } from '../../utils';
import AddVenueAccess from '../Account/AddVenueAccess';
import InviteCostumer from '../Account/InviteCostumer';
import AddCrewMember from '../Crew/AddCrewMember';
import AddGuestList from '../GuestList/AddGuestList';
import CreateBusinessUser from './CreateBusinessUser';
import ImportVenue from './ImportVenue';
import InsertGuest from './InsertGuest';

interface HeaderProps {
  page: mainPages;
  reload: () => void;
  isVenueAccess?: boolean;
  businessUserID?: any;
  pageId?: any;
  crewLicense?: any;
  isLoading?: boolean;
  signedInUser?: GetSignedUserDataQueryHookResult;
}

const Header: FC<HeaderProps> = ({ page, reload, isVenueAccess, businessUserID, pageId, crewLicense, isLoading, signedInUser }) => {
  const [licenseEnded, setLicenseEnded] = useState<boolean>(false);
  const { t } = useTranslation('page');
  const router = useRouter();
  const href = router.pathname === '/pages/[id]' ? `/events/create?venue=${router.query.id}` : checkPage(page).isEvents ? '/events/create' : '/';
  const showModal =
    checkPage(page).isVenues ||
    checkPage(page).isAccounts ||
    checkPage(page).isFestivals ||
    checkPage(page).isPromoters ||
    checkPage(page).isGuestList ||
    checkPage(page).isCrew;
  const importVenue = (checkPage(page).isVenues && !isVenueAccess) || checkPage(page).isFestivals || checkPage(page).isPromoters;
  const eventId = checkPage(page).isGuests ? router.query.id : '';
  const smallScreen = useSmallScreen();
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [openInviteCostumer, setOpenInviteCostumer] = useState<boolean>(false);
  const [guestModalType, setGuestModalType] = useState<'imp' | 'ist'>('ist');
  const { data: signedInUserData } = useGetSignedUserDataQuery();

  const pageText = checkPage(page).isEvents
    ? t('events.events')
    : checkPage(page).isGuests
    ? t('guests.guests')
    : checkPage(page).isVenues
    ? t('venues.venues')
    : checkPage(page).isAccounts
    ? t('accounts.accounts')
    : checkPage(page).isFestivals
    ? t('festivals.festivals')
    : checkPage(page).isPromoters
    ? t('promoters.promoters')
    : checkPage(page).isGuestList
    ? t('guestlist.guestlistTitle')
    : checkPage(page).isCrew
    ? t('crew.crew')
    : '';

  const linkText = checkPage(page).isEvents
    ? t('events.create')
    : smallScreen
    ? t('guests.add')
    : checkPage(page).isGuests
    ? t('guests.insert')
    : checkPage(page).isVenues && !isVenueAccess
    ? t('venues.create')
    : checkPage(page).isVenues && isVenueAccess
    ? t('accounts.addVenueAccess')
    : checkPage(page).isAccounts
    ? t('accounts.createBusinessUser')
    : checkPage(page).isFestivals
    ? t('festivals.importFestivals')
    : checkPage(page).isPromoters
    ? t('promoters.importPromoters')
    : checkPage(page).isGuestList || checkPage(page).isCrew
    ? t('guests.add')
    : '';

  const handleOpenModal = () => (crewLicense === null && licenseEnded && !isLoading ? setOpenModal(false) : setOpenModal(true));

  useEffect(() => {
    setLicenseEnded(!!((checkPage(page).isCrew && crewLicense === null) || (crewLicense && dayjs().diff(dayjs(crewLicense.endTime)) <= 7)));
  }, [crewLicense, isLoading, pageId]);

  return (
    <>
      {crewLicense === null && licenseEnded ? (
        <div className="license-expired">
          <Image src={'/icons/info_white.svg'} alt="license expired card" width={44} height={44} layout="fixed" />
          <div className="message">
            <h1>{t('crew.licenseExpiredTitle')}</h1>
            <h2>{t('crew.licenseExpiredMessage')}</h2>
          </div>

          <a className="button" href="mailto:support@partymate.app">
            {t('crew.buyLicense')}
          </a>
        </div>
      ) : (
        <></>
      )}

      <div className="header-content">
        <h1>{pageText}</h1>
        <div className="header-buttons">
          {checkPage(page).isGuests ? (
            <>
              <div
                className="import-button"
                onClick={() => {
                  setOpenModal(!openModal);
                  setGuestModalType('imp');
                }}>
                <DownloadOutlined size={16} />
                <h2 className="text">{t(`guests.${smallScreen ? 'importShort' : 'import'}`)}</h2>
              </div>

              <div
                className="create-button"
                onClick={() => {
                  setOpenModal(!openModal);
                  setGuestModalType('ist');
                }}>
                <Image src={'/icons/plus.svg'} layout="fixed" width={16} height={16} alt="PM_create_link" />
                <h2 className="text">{linkText}</h2>
              </div>

              <InsertGuest
                show={openModal}
                setShow={setOpenModal}
                eventID={!eventId ? '' : Array.isArray(eventId) ? eventId[0] : eventId}
                isImport={guestModalType === 'imp'}
                reload={reload}
              />
            </>
          ) : showModal ? (
            <>
              {checkPage(page).isAccounts && signedInUserData?.signedInUser?.isRootAdmin ? (
                <div
                  className={`create-button ${crewLicense === null && licenseEnded && !isLoading ? 'disabled' : ''}`}
                  onClick={() => setOpenInviteCostumer(true)}>
                  <Image src={'/icons/plus.svg'} layout="fixed" width={16} height={16} alt="PM_create_link" />
                  <h2 className="text">{t('accounts.inviteNewCustomer')}</h2>
                </div>
              ) : (
                <></>
              )}

              {importVenue && !signedInUserData?.signedInUser?.isRootAdmin ? (
                <></>
              ) : (
                <div className={`create-button ml ${crewLicense === null && licenseEnded && !isLoading ? 'disabled' : ''}`} onClick={handleOpenModal}>
                  <Image src={'/icons/plus.svg'} layout="fixed" width={16} height={16} alt="PM_create_link" />
                  <h2 className="text">{linkText}</h2>
                </div>
              )}

              {importVenue ? (
                <ImportVenue show={openModal} setShow={setOpenModal} isFestivals={checkPage(page).isFestivals} isPromoters={checkPage(page).isPromoters} />
              ) : checkPage(page).isVenues && isVenueAccess ? (
                <AddVenueAccess show={openModal} setShow={setOpenModal} businessUserID={businessUserID} reload={reload} />
              ) : checkPage(page).isGuestList ? (
                <AddGuestList setShow={setOpenModal} show={openModal} reload={reload} />
              ) : // <></>
              checkPage(page).isCrew ? (
                <AddCrewMember show={openModal} setShow={setOpenModal} pageId={pageId} reload={reload} />
              ) : (
                <CreateBusinessUser setShow={setOpenModal} show={openModal} />
              )}
            </>
          ) : (
            <Link href={href} passHref>
              <div className="create-button">
                <Image src={'/icons/plus.svg'} layout="fixed" width={16} height={16} alt="PM_create_link" />
                <h2 className="text">{linkText}</h2>
              </div>
            </Link>
          )}
        </div>
      </div>

      <InviteCostumer setShow={setOpenInviteCostumer} show={openInviteCostumer} reload={reload} />
    </>
  );
};

export default Header;
