import { Button, SelectProps } from 'antd';
import Modal from 'antd/lib/modal/Modal';
import DatePicker from 'antd/lib/date-picker';
import message from 'antd/lib/message';
import { Dispatch, FC, SetStateAction, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useCreateGuestListMutation, useGetEventsDataQuery } from '../../../graphql/types';
import { SimpleInputState } from '../../customTypes';
import { SelectInputComp } from '../CreateEvents/Form/Inputs';
import { LoginInputComp } from '../login/Inputs';
import moment, { Moment } from 'moment';

const TDatePicker: typeof DatePicker = DatePicker;

interface AddGuestListProps {
  show: boolean;
  setShow: Dispatch<SetStateAction<boolean>>;
  reload: () => void;
}

const AddGuestList: FC<AddGuestListProps> = ({ show, setShow, reload }) => {
  const style = { height: 40 };
  const [createGuestList] = useCreateGuestListMutation();
  const { t } = useTranslation('page');
  const [disabled, setDisabled] = useState<boolean>(false);
  const [event, setEvent] = useState('');
  const [showStartTimeError, setShowStartTimeError] = useState(false);
  const [startLargerError, setStartLargerError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedPage, setSelectedPage] = useState<{
    id: string;
    name: string;
  }>({
    id: '',
    name: '',
  });

  useEffect(() => {
    if (typeof window !== undefined) {
      setSelectedPage({
        id: localStorage.getItem('page_id') || '',
        name: localStorage.getItem('page_name') || '',
      });
    }
  }, [localStorage.getItem('page_id'), localStorage.getItem('page_name')]);

  const [name, setName] = useState<SimpleInputState>({
    value: '',
    error: '',
  });

  const [startTime, setStartTime] = useState<{
    value: Moment | undefined;
    error: string;
  }>({
    value: undefined,
    error: '',
  });

  const [endTime, setEndTime] = useState<Moment>();

  const eventRef = useRef<SelectProps>(null);
  const now = new Date();

  const { data, refetch } = useGetEventsDataQuery({
    variables: {
      filter: {
        managedBySignedInBusinessUser: true,
        startDateAfter: now.toDateString(),
      },
    },
  });

  const handleSearch = async (input: string) => {
    await refetch({ search: input });
  };

  const handleSubmit = async () => {
    setLoading(true);
    if (name.value === '') {
      setName({
        ...name,
        error: 'This field is required',
      });
      setLoading(false);
      return;
    }

    if (startTime.value === undefined) {
      setShowStartTimeError(true);
      setLoading(false);
      return;
    }

    if (startTime.value && endTime && new Date(startTime.value.toISOString()) > new Date(endTime.toISOString())) {
      setStartLargerError(true);
      setLoading(false);
      return;
    }

    await createGuestList({
      variables: {
        input: {
          title: name.value,
          eventId: event !== '' && JSON.parse(event).id ? JSON.parse(event).id : null,
          pageId: selectedPage.id,
          startTime: startTime.value?.toDate(),
          endTime: endTime?.toDate(),
        },
      },
    })
      .then(() => {
        setLoading(false);
        message.success('The guest list was created!');
        reload();
        clearState();
        setShow(false);
        setShowStartTimeError(false);
      })
      .catch(e => {
        const msg = e.message ? e.message.toString() : 'Something went wrong, please try again later.';
        message.error(msg);
      });
  };

  const clearState = () => {
    setEvent('');
    setName({
      value: '',
      error: '',
    });

    setStartTime({
      value: undefined,
      error: '',
    });
    setEndTime(undefined);
    setDisabled(false);
    setLoading(false);
    setStartLargerError(false);
    setShowStartTimeError(false);
  };

  useEffect(() => {
    if (event !== '') {
      const eventJson = JSON.parse(event);
      const { startDate, endDate } = eventJson;

      setName({
        ...name,
        value: eventJson.name,
      });

      const startDateToMoment = startDate ? moment(startDate) : moment();
      const endDateToMoment = endDate ? moment(endDate) : startDateToMoment.add(8, 'hours');
      setStartTime({
        value: startDateToMoment,
        error: '',
      });
      setEndTime(endDateToMoment);
    }
  }, [event]);

  const disabledStartDate = current => {
    return current <= moment();
  };

  const disabledEndDate = current => {
    return current <= moment(startTime.value);
  };

  return (
    <Modal
      visible={show}
      onCancel={() => {
        setShow(!show);
        clearState();
      }}
      footer={
        <div className="modal-footer">
          <Button
            style={{
              padding: 24,
            }}
            className="button-left"
            onClick={() => {
              setShow(false);
              clearState();
            }}>
            {t('events.cancel')}
          </Button>
          <Button
            style={{
              padding: 24,
            }}
            className="button-right"
            onClick={handleSubmit}
            disabled={disabled}
            loading={loading}>
            {t('events.submit')}
          </Button>
        </div>
      }
      className="guestlist-modal modal-container">
      <h1 className="title">
        {t('guestlist.add', {
          page: selectedPage.name,
        })}
      </h1>

      <SelectInputComp
        value={event}
        setValue={setEvent}
        eventID={undefined}
        editstring={''}
        noIcon={true}
        iconsrc=""
        placeholder={t('events.eventOptional')}
        bottomtext={t('events.eventOptional')}
        inputref={eventRef}
        optionsArray={
          data
            ? data.events.map(e => {
                return JSON.stringify({
                  name: e.name,
                  id: e.id,
                  startDate: e.startDate,
                  endDate: e.endDate,
                  coverImage: e.coverImageUrl,
                });
              })
            : []
        }
        showSearch
        onSearch={e => handleSearch(e)}
        loading={loading}
        isGuestList
        hasStaticEditstring={true}
        style={{
          width: '100%',
        }}
      />

      <LoginInputComp
        style={style}
        state={name}
        setState={setName}
        placeholder={t('guestlist.setName')}
        onChange={e => {
          setName({
            ...name,
            value: e.target.value,
            error: '',
          });
        }}
        disabled={disabled}
      />

      <div className="modal-categories columnOnMobile">
        <div
          className="modal-categorie"
          style={{
            display: 'flex',
            flexDirection: 'column',
          }}>
          <TDatePicker
            id="start-date-picker"
            showTime={{ format: 'HH:mm' }}
            format="DD.MM.YYYY HH:mm"
            disabledDate={disabledStartDate}
            onSelect={value => {
              if (value) {
                setStartTime({
                  value,
                  error: '',
                });
              }
            }}
            onChange={value => {
              if (value) {
                setStartTime({
                  value,
                  error: '',
                });
              }

              setShowStartTimeError(false);
            }}
            placeholder={t('guestlist.startDate')}
            style={{
              width: '100%',
              marginBottom: 8,
              borderRadius: 10,
              padding: '11px 18px',
            }}
            minuteStep={5}
            value={startTime.value}
            defaultValue={startTime.value}
          />
        </div>

        <div className="modal-categorie">
          <TDatePicker
            showTime={{ format: 'HH:mm' }}
            format="DD.MM.YYYY HH:mm"
            disabledDate={disabledEndDate}
            placeholder={t('guestlist.endDateOptional')}
            onChange={value => {
              if (value) setEndTime(value);
            }}
            onSelect={value => {
              if (value) {
                setEndTime(value);
              }
            }}
            style={{
              width: '100%',
              marginBottom: 8,
              borderRadius: 10,
              padding: '11px 18px',
            }}
            minuteStep={5}
            value={endTime}
            defaultValue={endTime}
          />
        </div>
        {showStartTimeError ? <h2 className="error-text">Required</h2> : <></>}
        {startLargerError ? <h2 className="error-text">The start date needs to be smaller than the end date.</h2> : <></>}
      </div>
    </Modal>
  );
};

export default AddGuestList;
