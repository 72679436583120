import FileDoneOutlined from '@ant-design/icons/FileDoneOutlined';
import LoadingOutlined from '@ant-design/icons/LoadingOutlined';
import UploadOutlined from '@ant-design/icons/UploadOutlined';

import message from 'antd/lib/message';
import Button from 'antd/lib/button';
import Upload from 'antd/lib/upload';
import Modal from 'antd/lib/modal/Modal';
import { ChangeEvent, Dispatch, FC, SetStateAction, useState } from 'react';
import { useTranslation } from 'react-i18next';
import readXlsxFile from 'read-excel-file';

import { GuestStatus, useImportEventGuestsMutation, useInsertEventGuestMutation } from '../../../graphql/types';
import { LoginInputComp } from '../login/Inputs';
import { emailReg, phoneReg, SimpleInputState } from '../../customTypes';

const uploadButton = (loading: boolean, done: boolean) => (
  <div className="uploadButton">
    {done ? (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          width: '100%',
          cursor: 'pointer',
        }}>
        <div style={{ width: '20%' }}>
          <FileDoneOutlined style={{ fontSize: 30 }} />
        </div>
        <div style={{ width: '75%' }}>
          <h2>File uploaded</h2>
          <h3>Press here to upload other file</h3>
        </div>
      </div>
    ) : loading ? (
      <LoadingOutlined />
    ) : (
      <Button icon={<UploadOutlined />}>Click to Upload</Button>
    )}
  </div>
);

interface InsertGuestProps {
  show: boolean;
  setShow: Dispatch<SetStateAction<boolean>>;
  eventID: string;
  isImport: boolean;
  reload: () => void;
}

const InsertGuest: FC<InsertGuestProps> = ({ show, setShow, eventID, isImport, reload }) => {
  const { t } = useTranslation('page');
  const title = t(`guests.${isImport ? 'selectExcel' : 'insert'}`);
  const style = { height: 40 };

  // use states
  const [first, setFirst] = useState<SimpleInputState>({
    value: '',
    error: '',
  });

  const [last, setLast] = useState<SimpleInputState>({
    value: '',
    error: '',
  });

  const [email, setEmail] = useState<SimpleInputState>({
    value: '',
    error: '',
  });

  const [phone, setPhone] = useState<SimpleInputState>({
    value: '',
    error: '',
  });

  const [dates, setDates] = useState<SimpleInputState>({
    value: '',
    error: '',
  });

  const [disabled, setDisabled] = useState<boolean>(false);
  const [file, setFile] = useState<{
    url: string;
    loading: boolean;
    name: string;
  }>({
    url: '',
    loading: false,
    name: '',
  });
  const [xlsx, setXlsx] = useState<any>();
  // use states end

  // graphql
  const [insertGuestList] = useInsertEventGuestMutation();
  const [inviteGuestListExcelImport] = useImportEventGuestsMutation();
  // graphql end

  // functions
  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const place = e.target.placeholder.toLowerCase();
    const val = e.target.value;

    // placeholders
    const placeFirst = place.includes('first');
    const placeLast = place.includes('last');
    const placeEmail = place.includes('email');
    const placePhone = place.includes('phone');
    const placeDates = place.includes('dates');
    //

    // errors
    const emailErr = placeEmail && emailReg.test(val) ? '' : t('login.invalidEmail');

    const phoneErr = placePhone && phoneReg.test(val) ? '' : t('login.invalidPhone');

    const datesErr = placeDates && parseInt(val) >= 0 ? '' : 'Must be 0 or more';
    //

    if (placeEmail)
      setEmail({
        value: val,
        error: emailErr,
      });
    else if (placePhone)
      setPhone({
        value: val,
        error: phoneErr,
      });
    else if (placeDates)
      setDates({
        value: val,
        error: datesErr,
      });
    else if (placeFirst)
      setFirst({
        value: val,
        error: '',
      });
    else if (placeLast)
      setLast({
        value: val,
        error: '',
      });
  };

  const handleSubmit = async () => {
    const hasErrors = phone.error !== '' || email.error !== '' || dates.error !== '';
    const hasEmpty = phone.value === '' || email.value === '' || dates.value === '';

    setDisabled(true);
    if (isImport) {
      importExcel();
    } else if (hasErrors) {
      message.error('Fix all the errors to insert the guest');
      setTimeout(() => setDisabled(false), 3000);
      return;
    } else if (hasEmpty) {
      message.error('Fill all the required fields');
      setTimeout(() => setDisabled(false), 3000);
      return;
    } else if (!disabled) {
      insertGuest();
    }
  };

  const importExcel = async () => {
    if (!xlsx) {
      message.error('You need to upload a .xlsx file');
      setTimeout(() => setDisabled(false), 3000);
    } else
      await inviteGuestListExcelImport({
        variables: {
          guestListId: '1',
          input: xlsx,
        },
      })
        .then(() => {
          clearState();
          setShow(false);
          message.success('The guest was inserted');
          reload();
        })
        .catch(e => {
          message.error('Something went wrong');
          setTimeout(() => setDisabled(false), 3000);
        });
  };

  const insertGuest = async () => {
    await insertGuestList({
      variables: {
        eventID,
        status: GuestStatus.Approved,
        guestInput: {
          firstName: first.value,
          lastName: last.value,
          phone: phone.value,
          email: email.value,
          address: '',
          dates: parseInt(dates.value),
        },
      },
    })
      .then(() => {
        clearState();
        setShow(false);
        message.success('The guest was inserted');
        reload();
      })
      .catch(e => {
        message.error('Something went wrong');
        setTimeout(() => setDisabled(false), 3000);
      });
  };

  const clearState = () => {
    if (isImport) {
      setFile({
        url: '',
        loading: false,
        name: '',
      });
      setXlsx(undefined);
      return;
    }
    setFirst({
      value: '',
      error: '',
    });
    setLast({
      value: '',
      error: '',
    });
    setEmail({
      value: '',
      error: '',
    });
    setPhone({
      value: '',
      error: '',
    });
    setDates({
      value: '',
      error: '',
    });
    setDisabled(false);
  };

  const processExcelFile = file => {
    const excelGuests: any[] = [];
    readXlsxFile(file)
      .then(rows => {
        rows.map((row, index) => {
          if (index === 0) {
            // do nothing
          } else {
            excelGuests.push({
              firstName: row[0],
              lastName: row[1],
              email: row[2],
              phone: row[3],
              dates: row[4],
            });
            setXlsx(excelGuests);
          }
        });
      })
      .catch(() => {});
  };

  const getBase64 = (file, callback) => {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(file);
  };

  const beforeUploadFile = file => {
    const Xlsx = file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';

    if (!Xlsx) {
      message.error('You can only upload xlsx file!');
    }

    return Xlsx;
  };

  const handleChangeFile = info => {
    if (info.file.status === 'uploading') {
      setFile({
        ...file,
        loading: true,
      });
      return;
    }
    if (info.file.status === 'done') {
      processExcelFile(info.file.originFileObj);

      getBase64(info.file.originFileObj, fileURL => {
        setFile({
          ...file,
          url: fileURL,
          loading: false,
        });
      });
    }
  };
  // upload functions end

  return (
    <Modal
      visible={show}
      onCancel={() => setShow(!show)}
      footer={
        <div className="modal-footer">
          <div className="button-left" onClick={() => setShow(false)}>
            {t('events.cancel')}
          </div>
          <div className={`button-right ${disabled ? 'disabled' : ''}`} onClick={() => handleSubmit()}>
            {t('events.submit')}
          </div>
        </div>
      }
      className="modal-container">
      <h1 className="title">{title}</h1>

      {isImport ? (
        <Upload
          accept="image/png, image/gif, image/jpeg"
          name="avatar"
          listType="picture"
          showUploadList={false}
          beforeUpload={beforeUploadFile}
          onChange={handleChangeFile}
          action="/api/noop"
          maxCount={1}>
          {uploadButton(file.loading, file.url !== '')}
        </Upload>
      ) : (
        <>
          <LoginInputComp
            style={style}
            state={first}
            setState={setFirst}
            placeholder="First Name (optional)"
            onChange={e => handleInputChange(e)}
            disabled={disabled}
          />
          <LoginInputComp
            style={style}
            state={last}
            setState={setLast}
            placeholder="Last Name (optional)"
            onChange={e => handleInputChange(e)}
            disabled={disabled}
          />
          <LoginInputComp style={style} state={email} setState={setEmail} placeholder="Email" onChange={e => handleInputChange(e)} disabled={disabled} />
          <LoginInputComp style={style} state={phone} setState={setPhone} placeholder="Phone" onChange={e => handleInputChange(e)} disabled={disabled} />
          <LoginInputComp
            style={style}
            state={dates}
            setState={setDates}
            placeholder="Maximun Dates"
            type="number"
            min={0}
            onChange={e => handleInputChange(e)}
            disabled={disabled}
          />
        </>
      )}
    </Modal>
  );
};
export default InsertGuest;
