import { message, Segmented } from 'antd';
import Modal from 'antd/lib/modal/Modal';
import { useState } from 'react';
import { SetStateAction } from 'react';
import { Dispatch, FC } from 'react';
import { useTranslation } from 'react-i18next';
import { PageCategory, useSyncVenueWithFacebookMutation } from '../../../graphql/types';
import { SimpleInputState } from '../../customTypes';
import { LoginInputComp } from '../login/Inputs';
import Autocomplete from 'react-google-autocomplete';

interface ImportVenueProps {
  show: boolean;
  setShow: Dispatch<SetStateAction<boolean>>;
  isFestivals?: boolean;
  isPromoters?: boolean;
}

const ImportVenue: FC<ImportVenueProps> = ({ show, setShow, isFestivals, isPromoters }) => {
  const [syncVenueWithFacebook] = useSyncVenueWithFacebookMutation();
  const { t } = useTranslation('page');
  const style = { height: 40 };
  const [disabled, setDisabled] = useState<boolean>(false);
  const [readOnly, setReadOnly] = useState<boolean>(true);
  const [, setSelected] = useState<'bar' | 'club'>('bar');
  const [category, setCategory] = useState<PageCategory>(PageCategory.Club);
  const [showLocationPicker, setShowLocationPicker] = useState(false);
  const [street, setStreet] = useState<string>('');
  const [city, setCity] = useState<string>('');
  const [zip, setZip] = useState<string>('');
  const [country, setCountry] = useState<string>('');
  const [locationLatitude, setLocationLatitude] = useState<number>(0);
  const [locationLongitude, setLocationLongitude] = useState<number>(0);

  const [id, setId] = useState<SimpleInputState>({
    value: '',
    error: '',
  });

  const handleSubmit = async () => {
    setDisabled(true);
    if (id.value !== '')
      await syncVenueWithFacebook({
        variables: {
          pageID: id.value,
          category,
          override: showLocationPicker ? true : false,
          partialData: showLocationPicker
            ? {
                city,
                country,
                location: {
                  latitude: locationLatitude,
                  longitude: locationLongitude,
                },
                street,
                zip,
              }
            : undefined,
        },
      })
        .then(() => {
          message.success(`${isFestivals ? 'Festival' : isPromoters ? 'Promoter' : 'Venue'} was imported!`);
          clearState();
        })
        .catch(e => {
          const msg = e.message ? e.message.toString() : 'Something went wrong, please try again later.';
          setShowLocationPicker(true);
          message.error(msg);
          setTimeout(() => {
            setDisabled(false);
          }, 3000);
        });
  };

  const clearState = () => {
    setDisabled(false);
    setReadOnly(true);
    setSelected('bar');
    setId({
      value: '',
      error: '',
    });
  };
  return (
    <Modal
      visible={show}
      onCancel={() => {
        setShow(!show);
        clearState();
      }}
      footer={
        <div className="modal-footer">
          <div
            className="button-left"
            onClick={() => {
              setShow(false);
              clearState();
            }}>
            {t('events.cancel')}
          </div>
          <div className={`button-right ${disabled || id.value === '' ? 'disabled' : ''}`} onClick={() => handleSubmit()}>
            {t('guests.importShort')}
          </div>
        </div>
      }
      className="modal-container">
      <h1 className="title">{isFestivals ? t('festivals.importFestivals') : isPromoters ? t('promoters.importPromoters') : t('venues.create')}</h1>

      <form autoComplete="off">
        <LoginInputComp
          style={style}
          state={id}
          setState={setId}
          placeholder={t('venues.fbIDorUsername')}
          onChange={e =>
            setId({
              ...id,
              value: e.target.value,
            })
          }
          disabled={disabled}
          autoComplete="off123"
          onFocus={() => setReadOnly(false)}
          onBlur={() => setReadOnly(true)}
          readOnly={readOnly}
        />

        {!isFestivals && !isPromoters ? (
          <Segmented
            style={{
              marginTop: 10,
              padding: 6,
              borderRadius: 10,
            }}
            onChange={value => {
              setCategory(value.toString() as PageCategory);
            }}
            block
            options={[PageCategory.Club, PageCategory.Bar, PageCategory.Organizer, PageCategory.Festival]}
          />
        ) : (
          <></>
        )}

        {showLocationPicker ? (
          <Autocomplete
            style={{
              marginBottom: 20,
            }}
            className="custom-input white-background"
            placeholder={t('onboarding.locationInput')}
            apiKey={'AIzaSyAF9j3O--9-rIyVz-n8iFHH0QospATMt4Q'}
            onPlaceSelected={async place => {
              if (place) {
                const lat = place.geometry?.location?.lat();
                const lng = place.geometry?.location?.lng();
                const placeStreet = place.address_components?.[0].long_name;
                const placeCountry = place.address_components?.[place.address_components?.length - 1].long_name;
                const placeCity = place.address_components?.[place.address_components?.length - 2].long_name;

                setLocationLatitude(lat ? lat : 0);
                setLocationLongitude(lng ? lng : 0);
                setCountry(placeCountry ? placeCountry : '');
                setCity(placeCity ? placeCity : '');
                setStreet(placeStreet ? placeStreet : '');
                setZip('21035');
              }
            }}
            options={{
              types: ['geocode'],
            }}
          />
        ) : (
          <></>
        )}
      </form>
    </Modal>
  );
};

export default ImportVenue;
