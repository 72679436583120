import { SelectProps } from 'antd';
import message from 'antd/lib/message';
import Modal from 'antd/lib/modal/Modal';
import React, { Dispatch, FC, SetStateAction, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useGetSignedUserDataQuery, useGetVenuesDataQuery, useGrantVenueAccessMutation, UserVenuePermissions } from '../../../graphql/types';
import { SelectInputComp } from '../CreateEvents/Form/Inputs';

type permissions = 'Read' | 'Write' | 'WriteCapacity' | 'ReadStatistics' | 'ReadCompetition';
type venuesPermissions = permissions[];

const accountType = (name: string, key: permissions, selected: venuesPermissions, setSelected: Dispatch<SetStateAction<venuesPermissions>>) => {
  return (
    <div
      className={`modal-categorie ${selected.includes(key) ? 'selected' : ''} ${key === 'ReadCompetition' ? 'w-100' : ''}`}
      onClick={() => (selected.includes(key) ? setSelected(selected.filter(e => e != key)) : setSelected([...selected, key]))}>
      <h1>{name}</h1>
    </div>
  );
};

interface AddVenueAccessProps {
  show: boolean;
  setShow: Dispatch<SetStateAction<boolean>>;
  businessUserID: any;
  reload: () => void;
}

const AddVenueAccess: FC<AddVenueAccessProps> = ({ show, setShow, businessUserID, reload }) => {
  const [grantVenueAccess] = useGrantVenueAccessMutation();
  const { t } = useTranslation('page');
  const [disabled, setDisabled] = useState<boolean>(false);
  const [selected, setSelected] = useState<venuesPermissions>([]);
  const [venue, setVenue] = useState('');

  const user = useGetSignedUserDataQuery();
  const isAdmin = user.data && user.data.signedInUser && user.data.signedInUser.isRootAdmin;

  const venues = useGetVenuesDataQuery({
    notifyOnNetworkStatusChange: true,
    variables: {
      filter: {
        managedBySignedInBusinessUser: !isAdmin,
      },
    },
  });

  const handleSearch = async (input: string) => {
    await venues.refetch({ search: input });
  };

  const venueRef = useRef<SelectProps>(null);

  const handleSubmit = async () => {
    setDisabled(true);
    const venueID = venues.data?.venues.filter(e => e.name === venue)[0].id;
    await grantVenueAccess({
      variables: {
        businessUserID,
        venueID: venueID ? venueID : '',
        permissions: selected.length > 0 ? selected.map(e => UserVenuePermissions[e]) : [],
      },
    })
      .then(() => {
        message.success('Venue access added!');
        setShow(false);
        reload();
      })
      .catch(() => message.error('Something went wrong, please try again later.'));

    setTimeout(() => setDisabled(false), 3000);
  };

  return (
    <Modal
      visible={show}
      onCancel={() => setShow(!show)}
      footer={
        <div className="modal-footer">
          <div className="button-left" onClick={() => setShow(false)}>
            {t('events.cancel')}
          </div>
          <div className={`button-right ${disabled ? 'disabled' : ''}`} onClick={() => handleSubmit()}>
            {t('events.submit')}
          </div>
        </div>
      }
      className="modal-container">
      <h1 className="title">{t('accounts.addVenueAccess')}</h1>

      <SelectInputComp
        value={venue}
        setValue={setVenue}
        eventID={undefined}
        editstring={t('events.edit')}
        noIcon={true}
        iconsrc=""
        placeholder={t('venues.venues')}
        bottomtext={t('venues.venues')}
        inputref={venueRef}
        optionsArray={venues.data ? venues.data.venues.map(e => e.name) : []}
        showSearch
        onSearch={e => handleSearch(e)}
        loading={venues.loading}
      />
      <div className="modal-categories">
        <h1>{t('accounts.permissions')}</h1>
        {accountType(t('accounts.read'), 'Read', selected, setSelected)}
        {accountType(t('accounts.write'), 'Write', selected, setSelected)}
        {accountType(t('accounts.writeCapacity'), 'WriteCapacity', selected, setSelected)}
        {accountType(t('accounts.readStatistics'), 'ReadStatistics', selected, setSelected)}
        {accountType(t('accounts.readCompetition'), 'ReadCompetition', selected, setSelected)}
      </div>
    </Modal>
  );
};

export default AddVenueAccess;
