import message from 'antd/lib/message';
import Modal from 'antd/lib/modal/Modal';
import { Dispatch, FC, SetStateAction, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useCreateBusinessUserMutation, UserType } from '../../../graphql/types';
import { emailReg, SimpleInputState } from '../../customTypes';
import { LoginInputComp } from '../login/Inputs';

const accountType = (name: string, key: UserType, selected: UserType | '', setSelected: Dispatch<SetStateAction<UserType | ''>>) => {
  return (
    <div key={key} className={`modal-categorie ${selected === key ? 'selected' : ''}`} onClick={() => (selected === key ? setSelected('') : setSelected(key))}>
      <h1>{name}</h1>
    </div>
  );
};

interface CreateBusinessUserProps {
  show: boolean;
  setShow: Dispatch<SetStateAction<boolean>>;
}

const CreateBusinessUser: FC<CreateBusinessUserProps> = ({ show, setShow }) => {
  const { t } = useTranslation('page');
  const style = { height: 40 };
  const [signUpBusinessUser] = useCreateBusinessUserMutation();

  const [selected, setSelected] = useState<UserType | ''>('');

  const [first, setFirst] = useState<SimpleInputState>({
    value: '',
    error: '',
  });
  const [last, setLast] = useState<SimpleInputState>({
    value: '',
    error: '',
  });
  const [email, setEmail] = useState<SimpleInputState>({
    value: '',
    error: '',
  });
  const [password, setPassword] = useState<SimpleInputState>({
    value: '',
    error: '',
  });
  const [repeatPass, setRepeatPass] = useState<SimpleInputState>({
    value: '',
    error: '',
  });

  const [disabled, setDisabled] = useState<boolean>(false);

  const handleSubmit = async () => {
    setDisabled(true);

    if (first.value === '') setFirst({ ...first, error: 'Enter a first name' });
    else if (!emailReg.test(email.value)) setEmail({ ...email, error: 'Enter a valid email' });
    else if (password.value === '') setPassword({ ...password, error: 'Password can not be empty' });
    else if (repeatPass.value !== password.value) setRepeatPass({ ...repeatPass, error: 'The repeated password is not the same as password' });
    else if (selected !== '') {
      await signUpBusinessUser({
        variables: {
          email: email.value,
          password: password.value,
          userInput: {
            firstName: first.value,
            lastName: last.value,
            type: selected,
          },
        },
      })
        .then(() => {
          message.success('User was created!');
          clearState();
        })
        .catch(e => {
          const msg = e.message ? e.message.toString() : 'Something went wrong, please try again.';
          message.error(msg);
        });
    }

    setTimeout(() => {
      setDisabled(false);
      setFirst({ ...first, error: '' });
      setEmail({ ...email, error: '' });
      setPassword({ ...password, error: '' });
      setRepeatPass({ ...repeatPass, error: '' });
    }, 3000);
  };

  const clearState = () => {
    setSelected('');
    setFirst({
      value: '',
      error: '',
    });
    setLast({
      value: '',
      error: '',
    });
    setEmail({
      value: '',
      error: '',
    });
    setPassword({
      value: '',
      error: '',
    });
    setRepeatPass({
      value: '',
      error: '',
    });
    setDisabled(false);
  };

  return (
    <Modal
      visible={show}
      onCancel={() => {
        setShow(!show);
        clearState();
      }}
      footer={
        <div className="modal-footer">
          <div
            className="button-left"
            onClick={() => {
              setShow(false);
              clearState();
            }}>
            {t('events.cancel')}
          </div>
          <div className={`button-right ${disabled ? 'disabled' : ''}`} onClick={handleSubmit}>
            {t('events.createEvent.saveOnPc')}
          </div>
        </div>
      }
      className="modal-container">
      <h1 className="title">{t('accounts.createBusinessUser')}</h1>

      <LoginInputComp
        style={style}
        state={first}
        setState={setFirst}
        placeholder={t('accounts.firstName')}
        onChange={e =>
          setFirst({
            ...first,
            value: e.target.value,
          })
        }
        disabled={disabled}
      />
      <LoginInputComp
        style={style}
        state={last}
        setState={setLast}
        placeholder={t('accounts.lastName')}
        onChange={e =>
          setLast({
            ...last,
            value: e.target.value,
          })
        }
        disabled={disabled}
      />
      <LoginInputComp
        style={style}
        state={email}
        setState={setEmail}
        placeholder={t('accounts.email')}
        onChange={e =>
          setEmail({
            ...email,
            value: e.target.value,
          })
        }
        disabled={disabled}
      />
      <LoginInputComp
        style={style}
        state={password}
        setState={setPassword}
        placeholder={t('accounts.password')}
        onChange={e =>
          setPassword({
            ...password,
            value: e.target.value,
          })
        }
        disabled={disabled}
        type="password"
      />
      <LoginInputComp
        style={style}
        state={repeatPass}
        setState={setRepeatPass}
        placeholder={t('accounts.repeatPassword')}
        onChange={e =>
          setRepeatPass({
            ...repeatPass,
            value: e.target.value,
          })
        }
        disabled={disabled}
        type="password"
      />

      <div className="modal-categories">
        <h1>{t('accounts.accountType')}</h1>
        {Object.keys(UserType).map(key => accountType(t(`accounts.${key.toLowerCase()}`), UserType[key], selected, setSelected))}
      </div>
    </Modal>
  );
};

export default CreateBusinessUser;
