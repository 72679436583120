import { message, SelectProps } from 'antd';
import Modal from 'antd/lib/modal/Modal';
import { Dispatch, FC, SetStateAction, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useGetVenuesDataQuery, useInviteCostumerMutation } from '../../../graphql/types';
import { emailReg, SimpleInputState } from '../../customTypes';
import { SelectInputComp } from '../CreateEvents/Form/Inputs';
import { LoginInputComp } from '../login/Inputs';

interface InviteCostumerProps {
  show: boolean;
  setShow: Dispatch<SetStateAction<boolean>>;
  reload: () => void;
}

const InviteCostumer: FC<InviteCostumerProps> = ({ show, setShow, reload }) => {
  const [inviteCostumer] = useInviteCostumerMutation();
  const venues = useGetVenuesDataQuery({
    notifyOnNetworkStatusChange: true,
    variables: {
      filter: {
        managedBySignedInBusinessUser: false,
      },
    },
  });

  const handleSearch = async (input: string) => {
    await venues.refetch({ search: input });
  };

  const style = { height: 40 };
  const { t } = useTranslation('page');
  const [disabled, setDisabled] = useState<boolean>(false);
  const [disabledSubmit, setDisabledSubmit] = useState<boolean>(false);

  const [first, setFirst] = useState<SimpleInputState>({
    value: '',
    error: '',
  });

  const [last, setLast] = useState<SimpleInputState>({
    value: '',
    error: '',
  });

  const [email, setEmail] = useState<SimpleInputState>({
    value: '',
    error: '',
  });

  const [page, setPage] = useState<string>('');
  const venueRef = useRef<SelectProps>(null);

  const handleSubmit = async () => {
    setDisabled(true);
    setDisabledSubmit(true);

    const pageId = JSON.parse(page).id;
    await inviteCostumer({
      variables: {
        pageId,
        input: {
          email: email.value,
          firstName: first.value,
          lastName: last.value,
        },
      },
    })
      .then(() => {
        message.success('Costumer was invited!');
        setShow(false);
        clearState();
        reload();
      })
      .catch(err => {
        const msg = err.message ? err.message.toString() : 'Something went wrong, please try again later.';

        message.error(msg);

        setTimeout(() => {
          setDisabled(false);
          setDisabledSubmit(false);
        }, 3000);
      });
  };

  const clearState = () => {
    setDisabledSubmit(false);
    setDisabled(false);
    setPage('');

    setFirst({
      value: '',
      error: '',
    });

    setLast({
      value: '',
      error: '',
    });

    setEmail({
      value: '',
      error: '',
    });
  };

  useEffect(() => {
    if (page === '' || first.value === '' || last.value === '' || email.value === '' || email.error !== '') setDisabledSubmit(true);
    else setDisabledSubmit(false);
  }, [first, last, email, page]);

  return (
    <Modal
      visible={show}
      onCancel={() => {
        setShow(!show);
        clearState();
      }}
      footer={
        <div className="modal-footer">
          <div
            className="button-left"
            onClick={() => {
              setShow(false);
              clearState();
            }}>
            {t('events.cancel')}
          </div>
          <div className={`button-right ${disabledSubmit ? 'disabled' : ''}`} onClick={() => handleSubmit()}>
            {t('events.submit')}
          </div>
        </div>
      }
      className="modal-container">
      <h1 className="title">{t('accounts.inviteNewCustomer')}</h1>

      <p style={{ fontWeight: 500 }}>
        Invite a customer to Partymate Pro including a 30 days trial automatically applied to the selected venue. The user is instantly ready to go including
        password, important links for web and app, venue setup ,and license.
      </p>

      <div className="modal-categories">
        <LoginInputComp
          style={{ ...style, width: '45%' }}
          state={first}
          setState={setFirst}
          placeholder={t('accounts.firstName')}
          onChange={e =>
            setFirst({
              ...first,
              value: e.target.value,
            })
          }
          disabled={disabled}
        />

        <LoginInputComp
          style={{ ...style, width: '45%' }}
          state={last}
          setState={setLast}
          placeholder={t('accounts.lastName')}
          onChange={e =>
            setLast({
              ...last,
              value: e.target.value,
            })
          }
          disabled={disabled}
        />
      </div>

      <LoginInputComp
        style={style}
        state={email}
        setState={setEmail}
        placeholder={`${t('accounts.email')}`}
        onChange={e =>
          setEmail({
            ...email,
            value: e.target.value,
          })
        }
        disabled={disabled}
        onBlur={() => {
          if (email.value !== '' && !emailReg.test(email.value)) {
            setEmail({
              ...email,
              error: 'This email is not valid.',
            });
          }
        }}
      />

      <SelectInputComp
        value={page}
        setValue={setPage}
        eventID={undefined}
        editstring={'Select Venue'}
        hasStaticEditstring={true}
        noIcon={true}
        iconsrc=""
        placeholder={t('venues.venues')}
        bottomtext={t('venues.venues')}
        inputref={venueRef}
        optionsArray={
          venues.data
            ? venues.data.venues.map(e => {
                return JSON.stringify({
                  name: e.name,
                  id: e.id,
                });
              })
            : []
        }
        showSearch
        onSearch={e => handleSearch(e)}
        loading={venues.loading}
        isGuestList
      />
    </Modal>
  );
};

export default InviteCostumer;
