import { Input, message, Select } from 'antd';
import Modal from 'antd/lib/modal/Modal';
import Image from 'next/image';
import { Dispatch, FC, SetStateAction, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useGrantPageRoleMutation, UserPageRole } from '../../../graphql/types';
import { SimpleInputState } from '../../customTypes';
import { LoginInputComp } from '../login/Inputs';

interface AddCrewMemberProps {
  show: boolean;
  setShow: Dispatch<SetStateAction<boolean>>;
  pageId: any;
  reload: () => void;
}

const AddCrewMember: FC<AddCrewMemberProps> = ({ show, setShow, pageId, reload }) => {
  const [grantPageRole] = useGrantPageRoleMutation();
  const style = { height: 40 };
  const { t } = useTranslation('page');
  const [disabled, setDisabled] = useState<boolean>(false);
  const [, setOpenSelect] = useState<boolean>(false);
  const [emptyRoleError, setEmptyRoleError] = useState(false);

  const [first, setFirst] = useState<SimpleInputState>({
    value: '',
    error: '',
  });
  const [last, setLast] = useState<SimpleInputState>({
    value: '',
    error: '',
  });
  const [email, setEmail] = useState<SimpleInputState>({
    value: '',
    error: '',
  });
  const [role, setRole] = useState<Array<UserPageRole>>([]);

  const handleSubmit = async () => {
    setDisabled(true);

    if (first.value === '') {
      setFirst({
        ...first,
        error: 'This field is required',
      });
    }

    if (last.value === '') {
      setLast({
        ...last,
        error: 'This field is required',
      });
    }

    if (email.value === '') {
      setEmail({
        ...email,
        error: 'This field is required',
      });
    }

    if (role.length === 0) {
      setEmptyRoleError(true);
    } else {
      setEmptyRoleError(false);
    }

    if (email.value !== '' && last.value !== '' && first.value !== '' && role.length > 0) {
      await grantPageRole({
        variables: {
          input: {
            firstName: first.value,
            lastName: last.value,
            email: email.value,
            role,
            pageId,
          },
        },
      })
        .then(e => {
          if (e.data?.grantPageRole.success) {
            message.success('The crew member was added!');
            reload();
            clearState();
            setShow(false);
          }
        })
        .catch(err => {
          const msg = err.message ? err.message.toString() : 'Something went wrong, please try again.';
          message.error(msg);
          setTimeout(() => {
            setDisabled(false);
          }, 3000);
        });
    }
    setDisabled(false);
  };

  const options = Object.keys(UserPageRole).map(role => {
    return {
      label: role === UserPageRole.CheckInOnly ? 'Check in Only' : role === UserPageRole.SocialMedia ? 'Social Media' : role,
      value: role,
    };
  });

  const clearState = () => {
    setFirst({
      value: '',
      error: '',
    });

    setLast({
      value: '',
      error: '',
    });

    setEmail({
      value: '',
      error: '',
    });

    setRole([]);
    setEmptyRoleError(false);
    setDisabled(false);
    setOpenSelect(false);
  };

  return (
    <Modal
      visible={show}
      onCancel={() => {
        setShow(!show);
        clearState();
      }}
      footer={
        <div className="modal-footer">
          <div
            className="button-left"
            onClick={() => {
              setShow(false);
              clearState();
            }}>
            {t('events.cancel')}
          </div>
          <div className={`button-right ${disabled ? 'disabled' : ''}`} onClick={() => handleSubmit()}>
            {t('events.createEvent.saveOnPc')}
          </div>
        </div>
      }
      className="modal-container">
      <h1 className="title">{t('crew.add')}</h1>

      <div className="guestlist-details-modal-data-container multi">
        <div className="right-side full">
          <Select options={options} onChange={e => setRole(e)} value={role} allowClear placeholder="Role" className="select" />
        </div>
      </div>
      {emptyRoleError ? <h1 className="error-text">The role is required</h1> : <></>}

      <LoginInputComp
        style={style}
        state={first}
        setState={setFirst}
        placeholder={t('accounts.firstName')}
        onChange={e =>
          setFirst({
            ...first,
            value: e.target.value,
          })
        }
        disabled={disabled}
      />

      <LoginInputComp
        style={style}
        state={last}
        setState={setLast}
        placeholder={t('accounts.lastName')}
        onChange={e =>
          setLast({
            ...last,
            value: e.target.value,
          })
        }
        disabled={disabled}
      />
      <LoginInputComp
        style={style}
        state={email}
        setState={setEmail}
        placeholder={t('accounts.email')}
        onChange={e =>
          setEmail({
            ...email,
            value: e.target.value,
          })
        }
        disabled={disabled}
      />
    </Modal>
  );
};

export default AddCrewMember;
